// Traduzione in italiano

export default {
  com_ui_examples: 'Esempi',
  com_ui_new_chat: 'Nuova chat',
  com_ui_example_quantum_computing: 'Spiega il calcolo quantistico in termini semplici',
  com_ui_example_10_year_old_b_day:
    'Hai qualche idea creativa per il compleanno di un bambino di 10 anni?',
  com_ui_example_http_in_js: 'Come posso fare una richiesta HTTP in Javascript?',
  com_ui_capabilities: 'Capacità',
  com_ui_capability_remember: 'Ricorda ciò che l\'utente ha detto in precedenza nella conversazione',
  com_ui_capability_correction: 'Permette all\'utente di fornire correzioni di seguito',
  com_ui_capability_decline_requests: 'Addestrato a rifiutare richieste inappropriate',
  com_ui_limitations: 'Limitazioni',
  com_ui_limitation_incorrect_info: 'Può occasionalmente generare informazioni scorrette',
  com_ui_limitation_harmful_biased:
    'Può occasionalmente produrre istruzioni dannose o contenuti prevenuti',
  com_ui_limitation_limited_2021: 'Conoscenza limitata del mondo e degli eventi successivi al 2021',
  com_ui_input: 'Input',
  com_ui_close: 'Chiudi',
  com_ui_model: 'Modello',
  com_ui_select_model: 'Seleziona un modello',
  com_ui_use_prompt: 'Usa il prompt',
  com_ui_prev: 'Precedente',
  com_ui_next: 'Successivo',
  com_ui_prompt_templates: 'Modelli di prompt',
  com_ui_hide_prompt_templates: 'Nascondi modelli di prompt',
  com_ui_showing: 'Visualizzazione',
  com_ui_of: 'di',
  com_ui_entries: 'Voci',
  com_ui_pay_per_call:
    'Tutte le conversazioni AI in un unico posto. Paga per chiamata e non al mese',
  com_ui_enter: 'Inserisci',
  com_ui_submit: 'Invia',
  com_ui_upload_success: 'Caricamento del file riuscito',
  com_ui_upload_invalid: 'File invalido per il caricamento',
  com_ui_cancel: 'Annulla',
  com_ui_save: 'Salva',
  com_ui_copy_to_clipboard: 'Copia negli appunti',
  com_ui_copied_to_clipboard: 'Copiato negli appunti',
  com_ui_regenerate: 'Rigenera',
  com_ui_edit: 'Modifica',
  com_ui_success: 'Successo',
  com_ui_all: 'tutti',
  com_ui_clear: 'Cancella',
  com_ui_revoke: 'Revoca',
  com_ui_revoke_info: 'Revoca tutte le credenziali dell\'utente',
  com_ui_confirm_action: 'Conferma azione',
  com_ui_chats: 'chat',
  com_ui_delete_conversation: 'Elimina chat?',
  com_ui_delete_conversation_confirm: 'Questo eliminerà',
  com_auth_error_login:
    'Impossibile accedere con le informazioni fornite. Controlla le tue credenziali e riprova.',
  com_auth_error_login_rl:
    'Troppi tentativi di accesso da questo IP in un breve lasso di tempo. Per favore riprova più tardi.',
  com_auth_no_account: 'Non hai un account?',
  com_auth_sign_up: 'Registrati',
  com_auth_sign_in: 'Accedi',
  com_auth_google_login: 'Accedi con Google',
  com_auth_facebook_login: 'Accedi con Facebook',
  com_auth_github_login: 'Accedi con Github',
  com_auth_discord_login: 'Accedi con Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'Email necessaria',
  com_auth_email_min_length: 'L\'email deve essere di almeno 6 caratteri',
  com_auth_email_max_length: 'L\'email non deve essere più lunga di 120 caratteri',
  com_auth_email_pattern: 'Devi inserire un indirizzo email valido',
  com_auth_email_address: 'Indirizzo email',
  com_auth_password: 'Password',
  com_auth_password_required: 'Password necessaria',
  com_auth_password_min_length: 'La password deve essere di almeno 8 caratteri',
  com_auth_password_max_length: 'La password deve essere meno di 128 caratteri',
  com_auth_password_forgot: 'Hai dimenticato la Password?',
  com_auth_password_confirm: 'Conferma password',
  com_auth_password_not_match: 'Le password non corrispondono',
  com_auth_continue: 'Continua',
  com_auth_create_account: 'Crea il tuo account',
  com_auth_error_create:
    'Si è verificato un errore durante la registrazione del tuo account. Per favore, riprova.',
  com_auth_full_name: 'Nome completo',
  com_auth_name_required: 'Nome necessario',
  com_auth_name_min_length: 'Il nome deve essere di almeno 3 caratteri',
  com_auth_name_max_length: 'Il nome deve essere meno di 80 caratteri',
  com_auth_username: 'Nome utente (opzionale)',
  com_auth_username_required: 'Nome utente obbligatorio',
  com_auth_username_min_length: 'Il nome utente deve essere di almeno 2 caratteri',
  com_auth_username_max_length: 'Il nome utente deve essere meno di 20 caratteri',
  com_auth_already_have_account: 'Hai già un account?',
  com_auth_login: 'Accedi',
  com_auth_reset_password: 'Resetta la tua password',
  com_auth_click: 'Clicca',
  com_auth_here: 'QUI',
  com_auth_to_reset_your_password: 'per reimpostare la tua password.',
  com_auth_reset_password_link_sent: 'Email inviata',
  com_auth_reset_password_email_sent:
    'Ti è stata inviata un\'email con ulteriori istruzioni per resettare la tua password.',
  com_auth_error_reset_password:
    'Si è verificato un problema durante il reset della tua password. Non è stato trovato alcun utente con l\'indirizzo email fornito. Per favore, riprova.',
  com_auth_reset_password_success: 'Reset della password riuscito',
  com_auth_login_with_new_password: 'Ora puoi accedere con la tua nuova password.',
  com_auth_error_invalid_reset_token: 'Questo token per il reset della password non è più valido.',
  com_auth_click_here: 'Clicca qui',
  com_auth_to_try_again: 'per riprovare.',
  com_auth_submit_registration: 'Invia registrazione',
  com_auth_welcome_back: 'Bentornato',
  com_endpoint_open_menu: 'Apri menu',
  com_endpoint_bing_enable_sydney: 'Abilita Sydney',
  com_endpoint_bing_to_enable_sydney: 'Per abilitare Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing può utilizzare fino a 7k token per il \'contesto\', che può citare per la conversazione. Il limite specifico non è noto ma potrebbe incorrere in errori superando i 7k token',
  com_endpoint_bing_system_message_placeholder:
    'ATTENZIONE: l\'uso improprio di questa funzione può farti BANNARE dall\'uso di Bing! Clicca su \'Messaggio di sistema\' per le istruzioni complete e il messaggio predefinito se omesso, che è il preset \'Sydney\' considerato sicuro.',
  com_endpoint_system_message: 'Messaggio di sistema',
  com_endpoint_default_blank: 'predefinito: vuoto',
  com_endpoint_default_false: 'predefinito: falso',
  com_endpoint_default_creative: 'predefinito: creativo',
  com_endpoint_default_empty: 'predefinito: vuoto',
  com_endpoint_default_with_num: 'predefinito: {0}',
  com_endpoint_context: 'Contesto',
  com_endpoint_tone_style: 'Stile del tono',
  com_endpoint_token_count: 'Conteggio dei token',
  com_endpoint_output: 'Output',
  com_endpoint_google_temp:
    'Valori più alti = più casuali, mentre valori più bassi = più focalizzati e deterministici. Consigliamo di modificare questo o Top P ma non entrambi.',
  com_endpoint_google_topp:
    'Top-p cambia come il modello seleziona i token per l\'output. I token vengono selezionati da più probabili a meno probabili (vedi parametro topK) fino a quando la somma delle loro probabilità equivale al valore top-p.',
  com_endpoint_google_topk:
    'Top-k cambia come il modello seleziona i token per l\'output. Un top-k di 1 significa che il token selezionato è il più probabile tra tutti i token nel vocabolario del modello (chiamato anche decodifica avida), mentre un top-k di 3 significa che il token successivo viene selezionato tra i 3 token più probabili (usando la temperatura).',
  com_endpoint_google_maxoutputtokens:
    ' 	Massimo numero di token che possono essere generati nella risposta. Specificare un valore inferiore per risposte più brevi e un valore superiore per risposte più lunghe.',
  com_endpoint_google_custom_name_placeholder: 'Imposta un nome personalizzato per PaLM2',
  com_endpoint_prompt_prefix_placeholder:
    'Imposta istruzioni o contesto personalizzato. Ignorato se vuoto.',
  com_endpoint_custom_name: 'Nome personalizzato',
  com_endpoint_prompt_prefix: 'Prefisso del prompt',
  com_endpoint_temperature: 'Temperatura',
  com_endpoint_default: 'predefinito',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Max Output Tokens',
  com_endpoint_openai_temp:
    'Valori più alti = più casuali, mentre valori più bassi = più focalizzati e deterministici. Consigliamo di modificare questo o Top P ma non entrambi.',
  com_endpoint_openai_max:
    'Il massimo di token da generare. La lunghezza totale dei token di input e dei token generati è limitata dalla lunghezza del contesto del modello.',
  com_endpoint_openai_topp:
    'Un\'alternativa al campionamento con temperatura, chiamata campionamento del nucleo, dove il modello considera i risultati dei token con la massa di probabilità top_p. Quindi 0,1 significa che vengono considerati solo i token che compongono la massa di probabilità dell\'1%',
  com_endpoint_openai_freq:
    'Numero tra -2.0 e 2.0. I valori positivi penalizzano i nuovi token in base alla loro frequenza esistente nel testo finora, diminuendo la probabilità del modello di ripetere la stessa linea alla lettera.',
  com_endpoint_openai_pres:
    'Numero tra -2.0 e 2.0. I valori positivi penalizzano i nuovi token in base al fatto che compaiono nel testo finora, aumentando la probabilità del modello di parlare di nuovi argomenti.',
  com_endpoint_openai_custom_name_placeholder: 'Imposta un nome personalizzato per ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Imposta istruzioni personalizzate da includere nel Messaggio di Sistema. Predefinito: nessuno',
  com_endpoint_anthropic_temp:
    'Varia da 0 a 1. Usa temp più vicino a 0 per analitico / scelta multipla, e più vicino a 1 per compiti creativi e generativi. Consigliamo di modificare questo o Top P ma non entrambi.',
  com_endpoint_anthropic_topp:
    'Top-p cambia come il modello seleziona i token per l\'output. I token vengono selezionati da più probabili a meno probabili (vedi parametro topK) fino a quando la somma delle loro probabilità equivale al valore top-p.',
  com_endpoint_anthropic_topk:
    'Top-k cambia come il modello seleziona i token per l\'output. Un top-k di 1 significa che il token selezionato è il più probabile tra tutti i token nel vocabolario del modello (chiamato anche decodifica avida), mentre un top-k di 3 significa che il token successivo viene selezionato tra i 3 token più probabili (usando la temperatura).',
  com_endpoint_anthropic_maxoutputtokens:
    ' Massimo numero di token che possono essere generati nella risposta. Specifica un valore inferiore per risposte più brevi e un valore superiore per risposte più lunghe.',
  com_endpoint_anthropic_custom_name_placeholder: 'Imposta un nome personalizzato per Anthropic',
  com_endpoint_frequency_penalty: 'Penalità di frequenza',
  com_endpoint_presence_penalty: 'Penalità di presenza',
  com_endpoint_plug_use_functions: 'Usa funzioni',
  com_endpoint_plug_skip_completion: 'Salta completamento',
  com_endpoint_disabled_with_tools: 'disabilitato con strumenti',
  com_endpoint_disabled_with_tools_placeholder: 'Disabilitato con gli strumenti selezionati',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Imposta istruzioni personalizzate da includere nel Messaggio di Sistema. Predefinito: nessuno',
  com_endpoint_set_custom_name:
    'Imposta un nome personalizzato, nel caso in cui tu possa trovare questo preimpostato.',
  com_endpoint_import: 'Importa',
  com_endpoint_preset: 'Preimpostazione',
  com_endpoint_presets: 'Preimpostazioni',
  com_endpoint_preset_name: 'Nome preimpostato',
  com_endpoint_new_topic: 'Nuovo argomento',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Nascondi',
  com_endpoint_show: 'Mostra',
  com_endpoint_completion: 'Completamento',
  com_endpoint_agent: 'Agente',
  com_endpoint_show_what_settings: 'Mostra impostazioni {0}',
  com_endpoint_save: 'Salva',
  com_endpoint_export: 'Esporta',
  com_endpoint_save_as_preset: 'Salva come preimpostato',
  com_endpoint_presets_clear_warning:
    'Sei sicuro di voler cancellare tutti i preset? Questo sarà irriversibile.',
  com_endpoint_not_implemented: 'Non implementato',
  com_endpoint_no_presets: 'Nessun preset ancora',
  com_endpoint_not_available: 'Nessun endpoint disponibile',
  com_endpoint_clear_all: 'Cancella tutto',
  com_endpoint_view_options: 'Visualizza opzioni',
  com_endpoint_save_convo_as_preset: 'Salva conversazione come preset',
  com_endpoint_my_preset: 'Il mio preimpostato',
  com_endpoint_agent_model: 'Modello Agente (Consigliato: GPT-3.5)',
  com_endpoint_completion_model: 'Modello Completamento (Consigliato: GPT-4)',
  com_endpoint_func_hover: 'Abilitare l\'uso dei Plugin come funzioni OpenAI',
  com_endpoint_skip_hover:
    'Abilita la possibilità di saltare la fase di completamento, che rivede la risposta finale e le fasi generate',
  com_endpoint_config_key: 'Imposta Chiave API',
  com_endpoint_config_key_for: 'Imposta Chiave API per',
  com_endpoint_config_key_name: 'Chiave',
  com_endpoint_config_value: 'Inserisci valore per',
  com_endpoint_config_key_name_placeholder: 'Prima imposta una chiave API',
  com_endpoint_config_key_encryption: 'La tua chiave verrà crittografata ed eliminata al',
  com_endpoint_config_key_expiry: 'il tempo di scadenza',
  com_endpoint_config_key_import_json_key: 'Importa la chiave JSON dell\'account di servizio',
  com_endpoint_config_key_import_json_key_success:
    'Chiave JSON dell\'account di servizio importata correttamente',
  com_endpoint_config_key_import_json_key_invalid:
    'Chiave JSON del Service Account non valida, hai importato il file corretto?',
  com_endpoint_config_key_get_edge_key: 'Per ottenere il token di accesso per Bing, accedi a',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Usa gli strumenti di sviluppo o un\'estensione mentre sei loggato nel sito per copiare il contenuto del cookie _U. Se ciò fallisce, segui queste',
  com_endpoint_config_key_edge_instructions: 'Istruzioni',
  com_endpoint_config_key_edge_full_key_string: 'per fornire la stringa di cookie complete.',
  com_endpoint_config_key_chatgpt:
    'Per ottenere il tuo token di accesso per ChatGPT \'Versione gratuita\', accedi a',
  com_endpoint_config_key_chatgpt_then_visit: 'poi visita',
  com_endpoint_config_key_chatgpt_copy_token: 'Copia token di accesso.',
  com_endpoint_config_key_google_need_to: 'Devi',
  com_endpoint_config_key_google_vertex_ai: 'Abilitare Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API su Google Cloud, poi',
  com_endpoint_config_key_google_service_account: 'Crea un account di servizio',
  com_endpoint_config_key_google_vertex_api_role:
    'Assicurati di fare clic su \'Crea e continua\' per dare almeno il ruolo \'Utente Vertex AI\'. Infine, crea una chiave JSON da importare qui.',
  com_nav_export_filename: 'Nome del file',
  com_nav_export_filename_placeholder: 'Imposta il nome del file',
  com_nav_export_type: 'Tipo',
  com_nav_export_include_endpoint_options: 'Includi opzioni dell\'endpoint',
  com_nav_enabled: 'Abilitato',
  com_nav_not_supported: 'Non supportato',
  com_nav_export_all_message_branches: 'Esporta tutti i rami dei messaggi',
  com_nav_export_recursive_or_sequential: 'Ricorsivo o sequenziale?',
  com_nav_export_recursive: 'Ricorsivo',
  com_nav_export_conversation: 'Esporta conversazione',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistema',
  com_nav_theme_dark: 'Scuro',
  com_nav_theme_light: 'Chiaro',
  com_nav_clear: 'Cancella',
  com_nav_clear_all_chats: 'Cancella tutte le chat',
  com_nav_confirm_clear: 'Conferma la cancellazione',
  com_nav_close_sidebar: 'Chiudi la barra laterale',
  com_nav_open_sidebar: 'Apri la barra laterale',
  com_nav_log_out: 'Esci',
  com_nav_user: 'UTENTE',
  com_nav_clear_conversation: 'Cancella conversazioni',
  com_nav_clear_conversation_confirm_message:
    'Sei sicuro di voler cancellare tutte le conversazioni? Questa azione è irreversibile.',
  com_nav_help_faq: 'Aiuto & FAQ',
  com_nav_settings: 'Impostazioni',
  com_nav_search_placeholder: 'Cerca messaggi',
  com_nav_setting_general: 'Generale',
  com_nav_setting_data: 'Controllo dati',
};
